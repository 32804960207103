<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit investigation details</v-toolbar-title>

            <v-spacer />
            
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                >
                    <v-row>
                        <v-text-field
                            label="Date of Investigation"
                            v-model="investigationDateInput"
                            required
                            type="date"
                            placeholder="dd/mm/yyyy"
                            :max="currentDate"
                        ></v-text-field>
                    </v-row>

                    <v-row>
                        <v-textarea
                            placeholder="What type of work was carried out in relation to the Incident?"
                            v-model="typeOfWorkLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Was the customer present during those works?"
                            v-model="customerPresentLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Did the customer sign paperwork saying they were happy with the works?"
                            v-model="customerSignPaperworkLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="customerSignPaperworkLocal">
                        <v-alert type="info" dense>
                            Can you provide a copy of the paperwork.
                        </v-alert>
                    </v-row>

                    <v-row>
                        <v-select
                            label="Who attended the works?"
                            v-model="whoWorksAttendedByIdLocal"
                            :items="lookups.allAttendedBy"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-textarea
                            label="Enter VM Employee or Contract Partner details"
                            v-model="contactDetailsofWhoAttendedByLocal"
                            :rules="rules.required"
                            placeholder="Enter VM Employee or Contract Partner Technician Number and/or the name of the VM Employee or Contract Partner (and any relevant contact details)"
                            hint="Enter VM Employee or Contract Partner Technician Number and/or the name of the VM Employee or Contract Partner (and any relevant contact details)"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Details on the alleged hazard/damage"
                            v-model="detailsOfHazardDamageLocal"
                            :rules="rules.required"
                            placeholder="Please give any details on the alleged hazard/damage, such as measurements etc. (Take Video / Image)"
                            hint="Please give any details on the alleged hazard/damage, such as measurements etc. (Take Video / Image)"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="What else could have caused the hazard"
                            v-model="otherCauseHazardLocal"
                            :rules="rules.required"
                            placeholder="Is there anything else that could have caused the hazard? (Take Video / Image of why you think this)"
                            hint="Is there anything else that could have caused the hazard? (Take Video / Image of why you think this)"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Is the alleged hazard still present?"
                            v-model="hazardPresentLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="hazardPresentLocal">
                        <v-textarea
                            label="Works required, and who have you contacted?"
                            v-model="yesHazardPresentLocal"
                            :rules="rules.required"
                            placeholder="Describe what works are required and who you have contacted to organise this"
                            hint="Describe what works are required and who you have contacted to organise this"
                        ></v-textarea>
                    </v-row>

                    <v-row v-if="!hazardPresentLocal">
                        <v-textarea
                            label="Provide details of who repaired this"
                            v-model="noHazardPresentLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-select
                            label="Lightning strike or power surge: were isolators fitted?"
                            v-model="lightningStrikeIdLocal"
                            :items="lookups.allLightningStrike"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>

                    <v-row v-if="lightningStrikeIdLocal==600010051">
                        <v-alert type="info" dense>
                            Can you please ensure these are fitted.
                        </v-alert>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Does it look like any recent works by a third party may have been in the vicinity, that could have caused this hazard/damage?"
                            v-model="thirdPartyWorksLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="thirdPartyWorksLocal">
                        <v-alert type="info" dense>
                            Take Video / Image of this.
                        </v-alert>
                    </v-row>

                    <v-row v-if="thirdPartyWorksLocal">
                        <v-textarea
                            label="Please explain"
                            v-model="thirdPartyWorksDetailsLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Has the claimant been in contact with anyone else regarding this hazard/damage?"
                            v-model="hazardContactWithAnyoneLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="hazardContactWithAnyoneLocal">
                        <v-textarea
                            label="Please explain"
                            v-model="hazardContactWithAnyoneDetailsLocal"
                            :rules="rules.required"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Observations which may help decide liability"
                            v-model="observationsLocal"
                            :rules="rules.required"
                            placeholder="Do you have any observations based on the facts above which may help decide liability?"
                            hint="Do you have any observations based on the facts above which may help decide liability?"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Do Virgin Media have relevant records?"
                            v-model="recordsExistLocal"
                            :rules="rules.required"
                            placeholder="Do Virgin Media have any records in relation to the alleged incident? (Defect/ Section 81 records, Icoms, CCCS etc)"
                            hint="Do Virgin Media have any records in relation to the alleged incident? (Defect/ Section 81 records, Icoms, CCCS etc)"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import optionSetAgent from "../../Ods/optionSetAgent.js";
import tasksAgent from "../../Tasks/tasksAgent.js";

export default {
    props: {
        id: String,
        date: String,                   // "DD/MM/YYYY"
        typeOfWork: String,
        customerPresent: Boolean,
        customerSignPaperwork: Boolean,
        whoWorksAttendedById: Number,
        contactDetailsofWhoAttendedBy: String,
        detailsOfHazardDamage: String,
        otherCauseHazard: String,
        hazardPresent: Boolean,
        yesHazardPresent: String,
        noHazardPresent: String,
        lightningStrikeId: Number,
        thirdPartyWorks: Boolean,
        thirdPartyWorksDetails: String,
        hazardContactWithAnyone: Boolean,
        hazardContactWithAnyoneDetails: String,
        observations: String,
        recordsExist: String,
    },
    
    data: function () {
        return {
            investigationDateLocal: this.date,
            investigationDateFormat: "DD/MM/YYYY",
            typeOfWorkLocal: this.typeOfWork,
            customerPresentLocal: this.customerPresent,
            customerSignPaperworkLocal: this.customerSignPaperwork,
            whoWorksAttendedByIdLocal: this.whoWorksAttendedById,
            contactDetailsofWhoAttendedByLocal: this.contactDetailsofWhoAttendedBy,
            detailsOfHazardDamageLocal: this.detailsOfHazardDamage,
            otherCauseHazardLocal: this.otherCauseHazard,
            hazardPresentLocal: this.hazardPresent,
            yesHazardPresentLocal: this.yesHazardPresent,
            noHazardPresentLocal: this.noHazardPresent,
            lightningStrikeIdLocal: this.lightningStrikeId,
            thirdPartyWorksLocal: this.thirdPartyWorks,
            thirdPartyWorksDetailsLocal: this.thirdPartyWorksDetails,
            hazardContactWithAnyoneLocal: this.hazardContactWithAnyone,
            hazardContactWithAnyoneDetailsLocal: this.hazardContactWithAnyoneDetails,
            observationsLocal: this.observations,
            recordsExistLocal: this.recordsExist,

            currentDate: this.$moment().format('YYYY-MM-DD'),

            lookups: {
                allAttendedBy: [],
                allLightningStrike: [],
            },

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        investigationDateInput: {
            get: function() {
                return this.investigationDateLocal
                    ? this.$moment(this.investigationDateLocal, this.investigationDateFormat).format("YYYY-MM-DD")
                    : null;
            },
            set: function(newValue) {
                if (newValue)
                    this.investigationDateLocal = this.$moment(newValue).format(this.investigationDateFormat);
            }
        }
    },

    mounted: function () {
        var l = this.$coreUi.loading();

        Promise.all([
            optionSetAgent.getOptionSetValues("vm-pl-investigation---works-attended-by").then(data => {
                this.lookups.allAttendedBy = data.optionSetValueProperties;

                if (this.lookups.allAttendedBy.every(x => x.id !== this.whoWorksAttendedByIdLocal))
                    this.whoWorksAttendedByIdLocal = null;
           }),

           optionSetAgent.getOptionSetValues("vm---defendant---pl-investigation---lightning-strike").then(data => {
                this.lookups.allLightningStrike = data.optionSetValueProperties;

                if (this.lookups.allLightningStrike.every(x => x.id !== this.lightningStrikeIdLocal))
                    this.lightningStrikeIdLocal = null;
           }),
        ]).then(() => {

            l.dismiss();
            this.refreshValidation();

        }).catch(console.error);
    },

    methods: {

        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            tasksAgent.getTask(this.id).then(result => {

                // Set fields and save
                result.aspectData.formBuilder.formData["dateInvestigation"] = this.investigationDateLocal;
                result.aspectData.formBuilder.formData["memoTypeOfWork"] = this.typeOfWorkLocal;
                result.aspectData.formBuilder.formData["toggleCustomerPresent"] = this.customerPresentLocal;
                result.aspectData.formBuilder.formData["toggleCustomerSignPaperwork"] = this.customerSignPaperworkLocal;
                result.aspectData.formBuilder.formData["optionWhoWorksAttendedBy"] = this.whoWorksAttendedByIdLocal;
                result.aspectData.formBuilder.formData["memoContactDetailsOfWhoAttendedBy"] = this.contactDetailsofWhoAttendedByLocal;
                result.aspectData.formBuilder.formData["memoDetailsOfHazardDamage"] = this.detailsOfHazardDamageLocal;
                result.aspectData.formBuilder.formData["memoOtherCauseHazard"] = this.otherCauseHazardLocal;
                result.aspectData.formBuilder.formData["toggleHazardPresent"] = this.hazardPresentLocal;
                result.aspectData.formBuilder.formData["memoYesHazardPresent"] = this.hazardPresentLocal ? this.yesHazardPresentLocal : null;
                result.aspectData.formBuilder.formData["memoNoHazardPresent"] = this.hazardPresentLocal ? null : this.noHazardPresentLocal;
                result.aspectData.formBuilder.formData["optionSetLightning"] = this.lightningStrikeIdLocal;
                result.aspectData.formBuilder.formData["toggleThirdPartyWorks"] = this.thirdPartyWorksLocal;
                result.aspectData.formBuilder.formData["memoThirdPartyWorks"] = this.thirdPartyWorksDetailsLocal;
                result.aspectData.formBuilder.formData["toggleHazardContactWithAnyone"] = this.hazardContactWithAnyoneLocal;
                result.aspectData.formBuilder.formData["memoHazardContactWithAnyone"] = this.hazardContactWithAnyoneLocal ? this.hazardContactWithAnyoneDetailsLocal : null;
                result.aspectData.formBuilder.formData["memoObservations"] = this.observationsLocal;
                result.aspectData.formBuilder.formData["memoRecordsExist"] = this.recordsExistLocal;

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.participantPicker;
                delete result.aspectData.taskAssignedTo;
                delete result.aspectData.taskDetails;
                delete result.aspectData.taskDueDate;
                
                tasksAgent.saveTask(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>
